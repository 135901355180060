import React from "react";

import PropTypes from "prop-types";

import BreadcrumbComponent from "../../components/Breadcrumb/Breadcrumb";
import GetInternalLinkContext from "../utils/GetInternalLinkContext";

const Breadcrumb = ({ id, variant }) => {
  const link = GetInternalLinkContext({ id });

  const pages = [];

  const splitPath = link.path.split("/");

  // Remove first Element, its empty
  splitPath.shift();

  // Remove last Element, self
  splitPath.pop();

  if (splitPath.length > 0) {
    let currentPath = "";

    splitPath.forEach((path) => {
      // TODO: check if not language url part...
      currentPath = `/${path}`;
      const subLink = GetInternalLinkContext({ slug: currentPath });

      pages.push({
        current: false,
        href: subLink.path,
        name: subLink.title,
      });
    });
  }

  pages.push({
    current: true,
    href: link.path,
    name: link.title,
  });

  return (
    <BreadcrumbComponent
      pages={pages}
      variant={variant}
    />
  );
};

Breadcrumb.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary"]),
};

Breadcrumb.defaultProps = {
  id: null,
  variant: "primary",
};

export default Breadcrumb;
