import React from "react";

import { useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";

import useMediaQuery from "../../hooks/useMediaQuery";
import { ArrowLeftIcon18, ArrowRightIcon18 } from "../../icons";
import Button from "../Button/Button";

const Breadcrumb = ({ pages, variant }) => {
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const color = variant === "primary" ? "white" : "white-breadcrumb";
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isMobile ? (
        <nav className="flex text-dark-grey" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center">
            {pages.length < 2
              && (
                <li>
                  <div>
                    <Button size="small" color="dark-grey" href="/" title={t("backToHome")}>
                      <span>Start</span>
                    </Button>
                  </div>
                </li>
              )}
            {pages.length < 2
              ? pages.map((page) => (
                page.name && page.name !== "home" && page.name !== "Startseite"
                  && (
                    <li key={page.name}>
                      <div className="flex items-center">
                        <ArrowRightIcon18 aria-hidden="true" />
                        <Button
                          size="small"
                          color={page.current ? color : "dark-grey"}
                          href={page.href}
                          title={`${t("goTo")}: ${t(page.name)}`}
                          aria-current={page.current ? "page" : undefined}
                        >
                          {t(page.name)}
                        </Button>
                      </div>
                    </li>
                  )
              ))
              : (
                <li key={pages[pages.length - 2].name}>
                  <div className="flex items-center ml-18">
                    <ArrowLeftIcon18 aria-hidden="true" />
                    <Button
                      size="small"
                      color={pages[pages.length - 2].current ? color : "dark-grey"}
                      href={pages[pages.length - 2].href}
                      variant={variant}
                      title={`${t("backTo")}: ${t(pages[pages.length - 2].name)}`}
                      aria-current={pages[pages.length - 2].current ? "page" : undefined}
                    >
                      {t("backTo")}: {t(pages[pages.length - 2].name)}
                    </Button>
                  </div>
                </li>
              )}
          </ol>
        </nav>
      ) : (
        <nav className="flex text-dark-grey" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center">
            <li>
              <div>
                <Button size="small" variant={variant} color="dark-grey" href="/" title={t("backToHome")}>
                  <span>Start</span>
                </Button>
              </div>
            </li>
            {/* eslint-disable-next-line consistent-return */}
            {pages.map((page) => {
              if (page.name && page.name !== "home" && page.name !== "Startseite") {
                return (
                  <li key={page.name}>
                    <div className="flex items-center">
                      <ArrowRightIcon18 aria-hidden="true" />
                      <Button
                        size="small"
                        color={page.current ? color : "dark-grey"}
                        href={page.href}
                        variant={variant}
                        title={`${t("goTo")}: ${t(page.name)}`}
                        aria-current={page.current ? "page" : undefined}
                      >
                        {t(page.name)}
                      </Button>
                    </div>
                  </li>
                );
              }
            })}
          </ol>
        </nav>
      )}
    </>
  );
};

Breadcrumb.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({
    current: PropTypes.bool.isRequired,
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  variant: PropTypes.oneOf(["primary", "secondary"]),
};

Breadcrumb.defaultProps = {
  variant: "primary",
};

export default Breadcrumb;
