import React from "react";

import PropTypes from "prop-types";

import useSiteMetadata from "../../hooks/useSiteMetadata";

const Seo = (props) => {
  const {
    title,
    description,
    pathname,
    children,
  } = props;

  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();

  const seo = {
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    title: title || defaultTitle,
    twitterUsername,
    url: `${siteUrl}${pathname || ""}`,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <link rel="icon" href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>" />
      {children}
    </>
  );
};

Seo.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  pathname: PropTypes.string,
  title: PropTypes.string,
};

Seo.defaultProps = {
  children: null,
  description: "",
  pathname: "",
  title: "",
};

export default Seo;
