import React from "react";

import classNames from "classnames";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import ContentContainer from "../../components/ContentContainer/ContentContainer";
import Masonry from "../../components/Masonry/Masonry";
import Section from "../../components/Section/Section";
import useMediaQuery from "../../hooks/useMediaQuery";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ImageSlider from "../ImageSlider/ImageSlider";
import MainLayout from "../layouts/MainLayout";
import ProgramCard from "../Program/ProgramCard";
import Seo from "../Seo/Seo";

const ProgramsOverviewPage = ({
  data: {
    page,
    programs,
  },
}) => {
  const {
    filters,
  } = useSelector((state) => state.projectFilter);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { t } = useTranslation();

  const filtersDefinitions = [
    {
      id: "targetAudience",
      name: t("targetGroup"),
      options: [
        { label: t("Erwachsene"), value: "Erwachsene" },
        { label: t("Schulen"), value: "Schulen" },
        { label: t("Kitas"), value: "Kitas" },
      ],
    },
    {
      id: "type",
      name: t("kind"),
      options: [
        { label: t("Kreativaufgaben"), value: "Kreativaufgaben" },
        { label: t("Projekt"), value: "Projekt" },
        { label: t("Videoreihe"), value: "Videoreihe" },
        { label: t("Führung"), value: "Führung" },
      ],
    },
  ];

  return (
    <MainLayout breadcrumb={<Breadcrumb id={page.id} />}>
      <Section>
        <ContentContainer>
          <h1>{page.title}</h1>
        </ContentContainer>
        <div className="-mt-8 lg:-mt-18">
          <ImageSlider data={page.slider} />
        </div>
      </Section>
      <div className="lg:mb-60" />
      <Section>
        <Masonry breakpointCols={isMobile ? 2 : 3}>
          {programs.nodes?.map(
            (program, index) => <ProgramCard key={program.id} data={program} parentSlug={page.slug?.current} className={classNames({ "mt-60": index === 0 || index === 2 })} />,
          )}
        </Masonry>
      </Section>
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!, $graphqlLocale: [String]) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityProgramsOverview(id: {eq: $id}) {
      id
      title
      slug {
        current
      }
      _rawQuickInfoText
      slider {
        ...ImageSlider
      }
      Metadata {
        ...MetaData
      }
    }
    programs: allSanityProgram (
      filter: {images: {images: {elemMatch: {_key: {ne: null}}}}, i18n_lang: {in: $graphqlLocale} }
    ) {
      totalCount
      nodes {
        id
        slug {
          current
        }
        title
        targetAudience
        type
        images {
          images {
            ...ImageWithPreview
            alt
          }
        }
      }
    }
  }
`;

ProgramsOverviewPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default ProgramsOverviewPage;
